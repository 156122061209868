.icon {
    display: grid;
    place-items: center;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    transition: 200ms all;
}

$shadow-size: 10px;

.icon img {
    width: 60%;
    height: 60%;
}

.bug {
    background: #92BC2C;
    box-shadow: 0 0 $shadow-size #92BC2C;
}

.dark {
    background: #595761;
    box-shadow: 0 0 $shadow-size #595761;
}

.dragon {
    background: #0C69C8;
    box-shadow: 0 0 $shadow-size #0C69C8;
}

.electric {
    background: #F2D94E;
    box-shadow: 0 0 $shadow-size #F2D94E;
}

.fire {
    background: #FBA54C;
    box-shadow: 0 0 $shadow-size #FBA54C;
}

.fairy {
    background: #EE90E6;
    box-shadow: 0 0 $shadow-size #EE90E6;
}

.fighting {
    background: #D3425F;
    box-shadow: 0 0 $shadow-size #D3425F;
}

.flying {
    background: #A1BBEC;
    box-shadow: 0 0 $shadow-size #A1BBEC;
}

.ghost {
    background: #5F6DBC;
    box-shadow: 0 0 $shadow-size #5F6DBC;
}

.grass {
    background: #5FBD58;
    box-shadow: 0 0 $shadow-size #5FBD58;
}

.ground {
    background: #DA7C4D;
    box-shadow: 0 0 $shadow-size #DA7C4D;
}

.ice {
    background: #75D0C1;
    box-shadow: 0 0 $shadow-size #75D0C1;
}

.normal {
    background: #A0A29F;
    box-shadow: 0 0 $shadow-size #A0A29F;
}

.poison {
    background: #B763CF;
    box-shadow: 0 0 $shadow-size #B763CF;
}

.psychic {
    background: #FA8581;
    box-shadow: 0 0 $shadow-size #FA8581;
}

.rock {
    background: #C9BB8A;
    box-shadow: 0 0 $shadow-size #C9BB8A;
}

.steel {
    background: #5695A3;
    box-shadow: 0 0 $shadow-size #5695A3;
}

.water {
    background: #539DDF;
    box-shadow: 0 0 $shadow-size #539DDF;
}