// Angular Material Theme
@import "./theme.scss";

// Tailwind setup
@tailwind base;
@tailwind components;
@tailwind utilities;

// Font Awesome Icons
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
// For more icons see https://fontawesome.com/docs/web/use-with/scss#get-set-up

// ###################
// User styles
@import "./gradients.scss";
@import "./poketype-common.scss";

body,
html {
  height: 100%;
  margin: 0px auto;
  padding: 0px auto;
}

.article {
  width: 50%;
  @apply bg-white;
  @apply shadow-lg;
  @apply ml-auto;
  @apply mr-auto;
  @apply min-h-screen;
  @apply p-4;
}

// For sprites
.pixelated {
  image-rendering: pixelated;
}

// Card gradient

.card-gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.kbd {
  @apply px-2 ;
  @apply py-1.5 ;
  @apply text-xs ;
  @apply font-semibold ;
  @apply text-gray-800 ;
  @apply bg-gray-100 ;
  @apply border ;
  @apply border-gray-200 ;
  @apply rounded-lg ;
  @apply dark:bg-gray-600 ;
  @apply dark:text-gray-100 ;
  @apply dark:border-gray-500;;
}